<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar dense color="#003d6a" elevation="0" dark style="z-index: 9">
            <v-toolbar-title>Firma Araçları</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input type="text" v-model="searchFilterInput" class="form-control mr-3" style="max-width: 250px"
                placeholder="Arama..." v-on:keyup="onFilterTextBoxChanged()" />
              <RoleProvider slug="VENDOR_CAR_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton v-if="canItPass" color="light" variant="outline" size="sm" @click="openNewCarModal()"
                    class="py-2 px-3 font-weight-bold">
                    <font-awesome-icon :icon="['fas', 'plus']" class="mr-2" />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue style="width: 100%; height: calc(100vh - 145px)" class="ag-theme-balham" :columnDefs="columnDefs"
              :defaultColDef="defaultColDef" :rowData="rowData" :sideBar="sideBar" :rowSelection="rowSelection"
              :enableRangeSelection="true" :statusBar="statusBar" @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady" :getContextMenuItems="getContextMenuItems"></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <!-- :closeOnBackdrop="false" -->
    <CModal :title="vendorCarModalAddUpdateOptions.title" size="xl" :closeOnBackdrop="false"
      :show.sync="vendorCarModalAddUpdate" class="vendorCarModalAddUpdateForm modalNoBorder">
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="4">
                <CSelect label="Durum" :value.sync="vendorCarModalAddUpdateForm.status"
                  :options="vendorCarModalAddUpdateFormStatusOptions" />
              </CCol>
              <CCol md="12" lg="4">
                <CSelect label="Firma" :value.sync="vendorCarModalAddUpdateForm.vendorSlug"
                  :options="vendorCarModalAddUpdateFormVendorSlugOptions" />
              </CCol>
              <CCol md="12" lg="4">
                <CInput label="Araç Kodu" v-model="vendorCarModalAddUpdateForm.vendorCarCode" />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="4">
                <CInput label="Araç Markası" v-model="vendorCarModalAddUpdateForm.vendorCarBrand" />
              </CCol>
              <CCol md="12" lg="4">
                <CInput label="Araç Modeli" v-model="vendorCarModalAddUpdateForm.vendorCarModel" />
              </CCol>
              <CCol md="12" lg="4">
                <CInput label="Günlük KM Limiti" type="number" step="1" min="1" max="10000"
                  v-model="vendorCarModalAddUpdateForm.dailyRangeLimit" />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="4">
                <CSelect label="Yakıt Tipi" :value.sync="vendorCarModalAddUpdateForm.vendorCarFuel"
                  :options="vendorCarModalAddUpdateFormVendorCarFuelOptions" @change="
                    getCars(
                      vendorCarModalAddUpdateForm.vendorCarFuel,
                      vendorCarModalAddUpdateForm.vendorCarTransmission
                    )
                    " />
              </CCol>
              <CCol md="12" lg="4">
                <CSelect label="Vites Tipi" :value.sync="vendorCarModalAddUpdateForm.vendorCarTransmission
                  " :options="vendorCarModalAddUpdateFormVendorCarTransmissionOptions
    " @change="
    getCars(
      vendorCarModalAddUpdateForm.vendorCarFuel,
      vendorCarModalAddUpdateForm.vendorCarTransmission
    )
    " />
              </CCol>
              <CCol md="12" lg="4">
                <label class>Eşlenecek Araç</label>
                <v-select v-model="vendorCarModalAddUpdateForm.carId" :reduce="(vendorCarModalAddUpdateFormCarsOptions) =>
                  vendorCarModalAddUpdateFormCarsOptions.value
                  " :options="vendorCarModalAddUpdateFormCarsOptions"
                  :value="vendorCarModalAddUpdateForm.carId"></v-select>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="4">
                <CSelect label="Yakıt Politikası" :value.sync="vendorCarModalAddUpdateForm.fuelPolicy"
                  :options="vendorCarModalAddUpdateFormFuelPolicyOptions" />
              </CCol>
              <CCol md="12" lg="4">
                <CInput label="Depozito" type="number" step="1" min="0" v-model="vendorCarModalAddUpdateForm.deposit" />
              </CCol>
              <CCol md="12" lg="4">
                <CSelect label="Çift Kredi Kartı" :value.sync="vendorCarModalAddUpdateForm.doubleCreditCard"
                  :options="vendorCarModalAddUpdateFormDoubleCreditCardOptions" />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="4">
                <CInput label="Hasar Muafiyeti" type="number" step="1" min="0"
                  v-model="vendorCarModalAddUpdateForm.damageExemption" />
              </CCol>
              <CCol md="12" lg="4">
                <CInput label="Sürücü Yaşı (Min)" type="number" step="1" min="18"
                  v-model="vendorCarModalAddUpdateForm.driverAge" />
              </CCol>
              <CCol md="12" lg="4">
                <CInput label="Ehliyet Yaşı (Min)" type="number" step="1" min="1"
                  v-model="vendorCarModalAddUpdateForm.licenseYears" />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <RoleProvider slug="VENDOR_CAR_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton color="danger" v-if="vendorCarModalAddUpdateOptions.process === 'put' && canItPass
              " :disabled="vendorCarModalAddUpdateBtnDisable"
              @click="vendorCarDelete(vendorCarModalAddUpdateForm._id)">Sil</CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_CAR_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton color="success" :disabled="vendorCarModalAddUpdateBtnDisable" @click="vendorCarAddUpdate()" v-if="vendorCarModalAddUpdateOptions.process === 'put' && canItPass
              ">
              Düzenle
            </CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_CAR_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton color="success" :disabled="vendorCarModalAddUpdateBtnDisable" @click="vendorCarAddUpdate()" v-if="vendorCarModalAddUpdateOptions.process === 'post' && canItPass
              ">
              Ekle
            </CButton>
          </div>
        </RoleProvider>
      </template>
    </CModal>

    <CModal title="Toplu Eşleştirme" size="xl" :closeOnBackdrop="false" :show.sync="pairingModalStatus"
      class="pairingModal modalNoBorder">
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="12">
                <CAlert show color="info">
                  Seçtiğiniz
                  <strong>{{ pairingForm.vendorCars.length }} araç</strong>
                  toplu olarak güncellenecek!
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="2">
                <CSelect label="Durum" :value.sync="pairingForm.status"
                  :options="vendorCarModalAddUpdateFormStatusOptions" />
              </CCol>
              <CCol md="12" lg="6">
                <label class>Eşlenecek Araç</label>
                <v-select v-model="pairingForm.carId" :reduce="(vendorCarModalAddUpdateFormCarsOptions) =>
                  vendorCarModalAddUpdateFormCarsOptions.value
                  " :options="vendorCarModalAddUpdateFormCarsOptions" :value="pairingForm.carId"></v-select>
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton color="success" :disabled="vendorCarModalAddUpdateBtnDisable" @click="vendorCarsPairing()">Eşleştir
        </CButton>
      </template>
    </CModal>

    <CModal title="Sıra Puanı Güncelleme" size="sm" :closeOnBackdrop="false" :show.sync="placementModalStatus"
      class="placementModal modalNoBorder">
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="12">
                <CAlert show color="info">
                  <strong>{{ placementForm.vendorCars.length }} araç</strong>
                  puanı güncellenecek!
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="12">
                <CInput label="Sıra Puanı" class="font-weight-bold" type="number" required
                  v-model="placementForm.placementScore" />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton color="success" :disabled="vendorCarModalAddUpdateBtnDisable" @click="updatePlacementScore()">Güncelle
        </CButton>
      </template>
    </CModal>

    <CModal title="Toplu Durum Güncelleme" size="sm" :closeOnBackdrop="false" :show.sync="bulkStatusModal"
      class="placementModal modalNoBorder">
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="12">
                <CAlert show color="info">
                  <strong>{{ placementForm.vendorCars.length }} araç</strong>
                  durumu güncellenecek!
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="12">
                <CSelect label="Durum" :value.sync="bulkStatusForm.status"
                  :options="vendorCarModalAddUpdateFormStatusOptions" />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton color="success" :disabled="vendorCarModalAddUpdateBtnDisable" @click="updateBulkStatus()">Güncelle
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";

var _ = require("lodash");
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

export default {
  name: "VendorCarSearch",
  created() {
    this.getVendorList();
    this.getCars();
    this.searchVendorCar();
    this.lastFilter();
  },
  query: {
    existQuery: String,
  },
  data() {
    return {
      // AG GRID
      pairingModalStatus: false,
      bulkStatusModal: false,
      bulkStatusForm: {
        status: true,
        vendorCars: [],
      },
      placementModalStatus: false,
      pairingForm: {
        status: true,
        carId: "",
        vendorCars: [],
        fuel: "",
        transmission: "",
      },
      placementForm: {
        placementScore: 0,
        vendorCars: [],
      },
      vendorCarsGridShow: false,
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            const v = params.value;
            return v == "active"
              ? "Aktif"
              : v == "pending"
                ? "Bekliyor"
                : "Pasif";
          },
          cellStyle: (params) => {
            const v = params.value;

            return v == "active"
              ? {
                color: "#2eb85c",
                fontWeight: "bold",
              }
              : v == "pending"
                ? {
                  color: "#F7DC6F",
                  fontWeight: "bold",
                }
                : {
                  color: "#e55353",
                  fontWeight: "bold",
                };
          },
          headerName: "Durum",
          width: 110,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          field: "placementScore",
          headerName: "Sıra Puanı",
          width: 100,
          cellStyle: { textAlign: "right" },
        },
        {
          field: "vendorDetail.brand",
          headerName: "Firma",
          width: 150,
        },
        {
          field: "carDetail._id",
          headerName: "CarID",
          width: 150,
          hide: true,
        },
        {
          field: "vendorCarBrand",
          cellRenderer: (params) => {
            return Func.strInitialsUpperCase(params.value);
          },
          headerName: "Araç Markası",
          width: 130,
        },
        {
          field: "vendorCarModel",
          cellRenderer: (params) => {
            return Func.strInitialsUpperCase(params.value);
          },
          headerName: "Araç Modeli",
          tooltipField: "vendorCarModel",
          width: 200,
        },
        {
          field: "vendorCarCode",
          headerName: "Firma Araç Kodu",
          width: 140,
        },
        {
          field: "carDetail.model",
          cellRenderer: (params) => {
            if (params.value)
              return (
                params.value +
                " (" +
                params.node.data.carDetail.fuel +
                " " +
                params.node.data.carDetail.transmission +
                ")"
              );
            else return "Araç eşleştirilmemiş!";
          },
          cellStyle: (params) => {
            if (!params.value) {
              return {
                color: "#e55353",
                fontWeight: "bold",
                "background-color": "black",
              };
            } else {
              return {
                "background-color": "lightgray",
                fontWeight: "bold",
              };
            }
          },
          headerName: "Eşleştirilen Araç Kataloğu",
          width: 230,
        },
        {
          field: "vendorCarFuel",
          cellRenderer: (params) => {
            if (params.value === "gas") return "Benzin";
            else if (params.value === "diesel") return "Dizel";
            else if (params.value === "hybrit") return "Hibrit";
            else if (params.value === "lpg") return "LPG";
            else if (params.value === "electric") return "Elektrikli";
            else return "-";
          },
          headerName: "Yakıt Tipi",
          width: 100,
        },
        {
          field: "vendorCarTransmission",
          cellRenderer: (params) => {
            if (params.value === "manuel") return "Manuel";
            else if (params.value === "automatic") return "Otomatik";
            else return "-";
          },
          headerName: "Vites Tipi",
          width: 100,
        },
        {
          field: "driverAge",
          headerName: "Min. Sürücü Yaşı",
          width: 140,
        },
        {
          field: "licenseYears",
          headerName: "Min. Ehliyet Yaşı",
          width: 140,
        },
        {
          field: "doubleCreditCard",
          cellRenderer: (params) => {
            return params.value === true ? "Evet" : "Hayır";
          },
          headerName: "Çift Kredi Kartı",
          width: 130,
        },
        {
          field: "dailyRangeLimit",
          cellRenderer: (params) => {
            return params.value + " KM";
          },
          headerName: "Günlük KM Limiti",
          width: 140,
        },
        {
          field: "fuelPolicy",
          cellRenderer: (params) => {
            if (params.value === "full") return "Dolu Depo Yakıt";
            else if (params.value === "sameLevel") return "Aynı Seviyede Yakıt";
            else return "-";
          },
          headerName: "Yakıt Politikası",
          width: 140,
        },
        {
          field: "deposit",
          cellRenderer: (params) => {
            return params.value + " TL";
          },
          headerName: "Depozito",
          width: 100,
        },
        {
          field: "damageExemption",
          cellRenderer: (params) => {
            return params.value + " TL";
          },
          headerName: "Hasar Muafiyeti",
          width: 140,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          {
            statusPanel: "agFilteredRowCountComponent",
          },
          {
            statusPanel: "agSelectedRowCountComponent",
          },
          {
            statusPanel: "agAggregationComponent",
          },
        ],
      },
      // FİRMA ARACI ARAMA
      searchVendorCarFormCollapsed: false,
      searchVendorCarFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchVendorCarFormVendorSlugOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchVendorCarFormVendorCarFuelOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: "gas",
          label: "Benzin",
        },
        {
          value: "diesel",
          label: "Dizel",
        },
        {
          value: "lpg",
          label: "LPG",
        },
        {
          value: "hybrit",
          label: "Hibrit",
        },
        {
          value: "electric",
          label: "Elektirik",
        },
        {
          value: "unspecified",
          label: "Belirtilmemiş",
        },
      ],
      searchVendorCarFormVendorCarTransmissionOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: "manuel",
          label: "Manuel",
        },
        {
          value: "automatic",
          label: "Otomatik",
        },
      ],
      searchVendorCarForm: {
        status: "",
        vendorSlug: "",
        vendorCarCode: "",
        vendorCarModel: "",
        vendorCarFuel: "",
        vendorCarTransmission: "",
      },
      // FİRMA ARACI EKLEME GÜNCELLEME MODAL
      vendorCarModalAddUpdate: false, // VendorCar Modal Add Update
      vendorCarModalAddUpdateBtnDisable: false, // VendorCar Modal Add Update button disabled enabled değişkeni
      vendorCarModalAddUpdateOptions: {
        // VendorCar Modal Add Update değişkenleri
        process: "",
        title: "",
        shake: {
          vendorCarCode: false,
          vendorCarBrand: false,
          vendorCarModel: false,
          dailyRangeLimit: false,
        },
      },
      vendorCarModalAddUpdateFormStatusOptions: [
        {
          value: "active",
          label: "Aktif",
        },
        {
          value: "passive",
          label: "Pasif",
        },
        {
          value: "pending",
          label: "Bekliyor",
        },
      ],
      vendorCarModalAddUpdateFormVendorSlugOptions: [],
      vendorCarModalAddUpdateFormVendorCarFuelOptions: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "gas",
          label: "Benzin",
        },
        {
          value: "diesel",
          label: "Dizel",
        },
        {
          value: "lpg",
          label: "LPG",
        },
        {
          value: "hybrit",
          label: "Hibrit",
        },
        {
          value: "electric",
          label: "Elektrikli",
        },
        {
          value: "unspecified",
          label: "Belirtilmemiş",
        },
      ],
      vendorCarModalAddUpdateFormVendorCarTransmissionOptions: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "manuel",
          label: "Manuel",
        },
        {
          value: "automatic",
          label: "Otomatik",
        },
      ],
      vendorCarModalAddUpdateFormCarsOptions: [],
      vendorCarModalAddUpdateFormFuelPolicyOptions: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "full",
          label: "Dolu Depo Yakıt",
        },
        {
          value: "sameLevel",
          label: "Aynı Seviyede Yakıt",
        },
      ],
      vendorCarModalAddUpdateFormDoubleCreditCardOptions: [
        {
          value: true,
          label: "Evet",
        },
        {
          value: false,
          label: "Hayır",
        },
      ],
      vendorCarModalAddUpdateFormErrors: {
        vendorCarCode: "",
        vendorCarBrand: "",
        vendorCarModel: "",
        dailyRangeLimit: "",
        deposit: "",
        damageExemption: "",
        driverAge: "",
        licenseYears: "",
      },
      vendorCarModalAddUpdateForm: {
        _id: "",
        status: false,
        vendorSlug: "",
        vendorCarCode: "",
        vendorCarBrand: "",
        vendorCarModel: "",
        dailyRangeLimit: 1,
        vendorCarFuel: "",
        vendorCarTransmission: "",
        carId: null,
        fuelPolicy: "",
        deposit: 0,
        doubleCreditCard: true,
        damageExemption: 0,
        driverAge: 18,
        licenseYears: 1,
      },
      searchFilterInput: "",
    };
  },
  components: {
    AgGridVue,
    vSelect,
    RoleProvider,
  },

  methods: {
    lastFilter() {
      this.searchFilterInput = JSON.parse(localStorage.getItem('VendorslastFilter') || '{}').VendorsCarsPageFilter || '';
    },
    getContextMenuItems(params) {
      var _this = this;
      var selectedRowCount = params.api.getSelectedRows().length;

      /**Aynı anda iki farklı yakıt tipli araç seçilemesin. */
      var vendorFuels = {};
      for (const item of params.api.getSelectedRows()) {
        vendorFuels[item.vendorCarFuel] = 1;
      }
      var selectedFuelCount = Object.keys(vendorFuels).length;

      /**Aynı anda iki farklı şanzıman tipli araç seçilemesin. */
      var vendorTransmissions = {};
      for (const item of params.api.getSelectedRows()) {
        vendorTransmissions[item.vendorCarTransmission] = 1;
      }
      var selectedTransmissionsCount = Object.keys(vendorTransmissions).length;

      if (selectedTransmissionsCount == 1 && selectedFuelCount == 1) {
        _this.pairingForm.fuel = params.api.getSelectedRows()[0].vendorCarFuel;
        _this.pairingForm.transmission =
          params.api.getSelectedRows()[0].vendorCarTransmission;
      }

      var result = [
        {
          name: "Eşleştir (" + selectedRowCount + " Araç)",
          disabled:
            selectedRowCount === 0 ||
              selectedTransmissionsCount !== 1 ||
              selectedFuelCount !== 1
              ? true
              : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            _this.pairingForm.vendorCars = [];
            for (const rowId of selectedRows) {
              _this.pairingForm.vendorCars.push(rowId._id);
            }
            _this.getCars(
              _this.pairingForm.fuel,
              _this.pairingForm.transmission
            );
            _this.pairingModalStatus = true;
          },
          cssClasses: ["redFont", "bold"],
        },
        {
          name: "Durum Değiştir (" + selectedRowCount + " Araç)",
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            _this.bulkStatusForm.vendorCars = [];
            for (const rowId of selectedRows) {
              _this.bulkStatusForm.vendorCars.push(rowId._id);
            }
            _this.bulkStatusModal = true;
          },
          cssClasses: ["redFont", "bold"],
        },
        {
          name: "Sıra Puanını Değiştir (" + selectedRowCount + " Araç)",
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            _this.placementForm.vendorCars = [];
            for (const rowId of selectedRows) {
              _this.placementForm.vendorCars.push(rowId._id);
            }
            _this.placementModalStatus = true;
          },
          cssClasses: ["redFont", "bold"],
        },
        "separator",
        "copy",
        "export",
      ];
      return result;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
      localStorage.setItem('VendorslastFilter', JSON.stringify({ VendorsCarsPageFilter: this.searchFilterInput }));
    },
    openNewCarModal() {
      this.cleanVendorCarModalAddUpdateForm();
      this.getCars();
      this.vendorCarModalAddUpdateOptions.process = "post";
      this.vendorCarModalAddUpdateOptions.title = "Yeni Firma Aracı Ekle";
      this.vendorCarModalAddUpdate = true;
    },
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    cleanVendorCarModalAddUpdateForm() {
      this.vendorCarModalAddUpdateForm._id = "";
      this.vendorCarModalAddUpdateForm.status =
        this.vendorCarModalAddUpdateFormStatusOptions[0].value;
      this.vendorCarModalAddUpdateForm.vendorSlug =
        this.vendorCarModalAddUpdateFormVendorSlugOptions[0].value;
      this.vendorCarModalAddUpdateForm.vendorCarCode = "";
      this.vendorCarModalAddUpdateForm.vendorCarBrand = "";
      this.vendorCarModalAddUpdateForm.vendorCarModel = "";
      this.vendorCarModalAddUpdateForm.dailyRangeLimit = 1;
      this.vendorCarModalAddUpdateForm.vendorCarFuel =
        this.vendorCarModalAddUpdateFormVendorCarFuelOptions[0].value;
      this.vendorCarModalAddUpdateForm.vendorCarTransmission =
        this.vendorCarModalAddUpdateFormVendorCarTransmissionOptions[0].value;
      this.vendorCarModalAddUpdateForm.carId = null;
      this.vendorCarModalAddUpdateForm.fuelPolicy =
        this.vendorCarModalAddUpdateFormFuelPolicyOptions[0].value;
      this.vendorCarModalAddUpdateForm.deposit = 0;
      this.vendorCarModalAddUpdateForm.doubleCreditCard =
        this.vendorCarModalAddUpdateFormDoubleCreditCardOptions[0].value;
      this.vendorCarModalAddUpdateForm.damageExemption = 0;
      this.vendorCarModalAddUpdateForm.driverAge = 18;
      this.vendorCarModalAddUpdateForm.licenseYears = 1;
    },
    getVendorList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor")
        .then((response) => {
          if (response.data.vendorList.length > 0) {
            response.data.vendorList.map(function (result) {
              _this.searchVendorCarFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });

              _this.vendorCarModalAddUpdateFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });
            });
          }
        });
    },
    searchVendorCar() {
      this.$Progress.start();
      let params = this.searchVendorCarForm,
        existQuery = this.$route?.query?.existQuery;
      if (existQuery) params.existQuery = existQuery;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor/cars", {
          params,
        })
        .then((response) => {
          this.rowData = _.reverse(response.data.vendorCarList);
          this.searchVendorCarFormCollapsed = false;
          this.vendorCarsGridShow = true;
          this.$Progress.finish();
        });
    },
    getCars(fuel, transmission) {
      if (fuel && transmission) {
        axios
          .get(process.env.VUE_APP_API_URL + "admin/cars", {
            params: {
              status: true,
              fuel: fuel,
              transmission: transmission,
            },
          })
          .then((response) => {
            if (
              response.data.result === "success" &&
              response.data.carList.length > 0
            ) {
              this.vendorCarModalAddUpdateFormCarsOptions = [];

              response.data.carList.forEach((value, key) => {
                this.vendorCarModalAddUpdateFormCarsOptions.push({
                  label: value.brand + " " + value.model,
                  value: value._id,
                });
              });
            } else this.vendorCarModalAddUpdateForm.carId = null;
          });
      }
    },
    vendorCarAddUpdate() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorCarModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios[this.vendorCarModalAddUpdateOptions.process](
        process.env.VUE_APP_API_URL + "admin/vendor/cars",
        this.vendorCarModalAddUpdateForm
      ).then((response) => {
        this.$Progress.finish(); // Progress true ise gizliyoruz
        this.vendorCarModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

        if (response.data.result === "success") {
          if (this.vendorCarsGridShow) this.searchVendorCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
          this.vendorCarModalAddUpdate = false; // Modal'ı kapattık
        }

        Vue.swal({
          icon: response.data.result,
          title: this.vendorCarModalAddUpdateOptions.title,
          html: response.data.message,
          confirmButtonText: "Tamam",
        });
      });
    },

    vendorCarsPairing() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorCarModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/cars/pairing",
          this.pairingForm,
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorCarModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            if (this.vendorCarsGridShow) this.searchVendorCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.pairingModalStatus = false; // Modal'ı kapattık
          }

          Vue.swal({
            icon: response.data.result,
            title: "Toplu Eşleştirme",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    updatePlacementScore() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorCarModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/cars/placementScore",
          this.placementForm,
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorCarModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            if (this.vendorCarsGridShow) this.searchVendorCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.placementModalStatus = false; // Modal'ı kapattık
          }

          Vue.swal({
            icon: response.data.result,
            title: "Sıra Puanı",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    updateBulkStatus() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorCarModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/cars/bulkStatus",
          this.bulkStatusForm,
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorCarModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            if (this.vendorCarsGridShow) this.searchVendorCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.bulkStatusModal = false; // Modal'ı kapattık
          }

          Vue.swal({
            icon: response.data.result,
            title: "Durum Güncelleme",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    vendorCarDelete(_id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu firma aracını silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/vendor/cars/" + _id,
                { withCredentials: true }
              );
              this.$Progress.finish();

              this.$store.dispatch({
                type: "successSwal",
                text: "Firma aracı başarılı bir şekilde silindi",
                refresh: true,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },

    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanVendorCarModalAddUpdateForm();
      this.vendorCarModalAddUpdateOptions.process = "put";
      this.vendorCarModalAddUpdateOptions.title = "Firma Aracı Güncelle";
      this.vendorCarModalAddUpdate = true;
      _this.getCars(
        params.node.data.vendorCarFuel,
        params.node.data.vendorCarTransmission
      );

      _this.vendorCarModalAddUpdateForm._id = params.node.data._id;
      _this.vendorCarModalAddUpdateForm.status = params.node.data.status;
      _this.vendorCarModalAddUpdateForm.vendorSlug =
        params.node.data.vendorSlug;
      _this.vendorCarModalAddUpdateForm.vendorCarCode =
        params.node.data.vendorCarCode;
      _this.vendorCarModalAddUpdateForm.vendorCarBrand =
        params.node.data.vendorCarBrand;
      _this.vendorCarModalAddUpdateForm.vendorCarModel =
        params.node.data.vendorCarModel;
      _this.vendorCarModalAddUpdateForm.dailyRangeLimit =
        params.node.data.dailyRangeLimit;
      _this.vendorCarModalAddUpdateForm.vendorCarFuel =
        params.node.data.vendorCarFuel;
      _this.vendorCarModalAddUpdateForm.vendorCarTransmission =
        params.node.data.vendorCarTransmission;
      _this.vendorCarModalAddUpdateForm.carId = params.node.data.carId;
      _this.vendorCarModalAddUpdateForm.fuelPolicy =
        params.node.data.fuelPolicy;
      _this.vendorCarModalAddUpdateForm.deposit = params.node.data.deposit;
      _this.vendorCarModalAddUpdateForm.doubleCreditCard =
        params.node.data.doubleCreditCard;
      _this.vendorCarModalAddUpdateForm.damageExemption =
        params.node.data.damageExemption;
      _this.vendorCarModalAddUpdateForm.driverAge = params.node.data.driverAge;
      _this.vendorCarModalAddUpdateForm.licenseYears =
        params.node.data.licenseYears;
      _this.vendorCarModalAddUpdate = true;
    },
  },
};
</script>
