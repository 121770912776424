var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "10" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "z-index": "9" },
                      attrs: {
                        dense: "",
                        color: "#003d6a",
                        elevation: "0",
                        dark: "",
                      },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Firma Araçları")]),
                      _c("v-spacer"),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end",
                          staticStyle: { "min-width": "550px" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchFilterInput,
                                expression: "searchFilterInput",
                              },
                            ],
                            staticClass: "form-control mr-3",
                            staticStyle: { "max-width": "250px" },
                            attrs: { type: "text", placeholder: "Arama..." },
                            domProps: { value: _vm.searchFilterInput },
                            on: {
                              keyup: function ($event) {
                                return _vm.onFilterTextBoxChanged()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.searchFilterInput = $event.target.value
                              },
                            },
                          }),
                          _c("RoleProvider", {
                            attrs: { slug: "VENDOR_CAR_CREATE" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ canItPass }) {
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      canItPass
                                        ? _c(
                                            "CButton",
                                            {
                                              staticClass:
                                                "py-2 px-3 font-weight-bold",
                                              attrs: {
                                                color: "light",
                                                variant: "outline",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openNewCarModal()
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: ["fas", "plus"],
                                                },
                                              }),
                                              _vm._v("Yeni Ekle "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    { staticClass: "p-0" },
                    [
                      _c("ag-grid-vue", {
                        staticClass: "ag-theme-balham",
                        staticStyle: {
                          width: "100%",
                          height: "calc(100vh - 145px)",
                        },
                        attrs: {
                          columnDefs: _vm.columnDefs,
                          defaultColDef: _vm.defaultColDef,
                          rowData: _vm.rowData,
                          sideBar: _vm.sideBar,
                          rowSelection: _vm.rowSelection,
                          enableRangeSelection: true,
                          statusBar: _vm.statusBar,
                          getContextMenuItems: _vm.getContextMenuItems,
                        },
                        on: {
                          rowDoubleClicked: _vm.onRowDoubleClicked,
                          "grid-ready": _vm.onGridReady,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "vendorCarModalAddUpdateForm modalNoBorder",
          attrs: {
            title: _vm.vendorCarModalAddUpdateOptions.title,
            size: "xl",
            closeOnBackdrop: false,
            show: _vm.vendorCarModalAddUpdate,
          },
          on: {
            "update:show": function ($event) {
              _vm.vendorCarModalAddUpdate = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("RoleProvider", {
                    attrs: { slug: "VENDOR_CAR_DELETE" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ canItPass }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.vendorCarModalAddUpdateOptions.process ===
                                "put" && canItPass
                                ? _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "danger",
                                        disabled:
                                          _vm.vendorCarModalAddUpdateBtnDisable,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vendorCarDelete(
                                            _vm.vendorCarModalAddUpdateForm._id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Sil")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("RoleProvider", {
                    attrs: { slug: "VENDOR_CAR_UPDATE" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ canItPass }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.vendorCarModalAddUpdateOptions.process ===
                                "put" && canItPass
                                ? _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled:
                                          _vm.vendorCarModalAddUpdateBtnDisable,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vendorCarAddUpdate()
                                        },
                                      },
                                    },
                                    [_vm._v(" Düzenle ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("RoleProvider", {
                    attrs: { slug: "VENDOR_CAR_CREATE" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ canItPass }) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.vendorCarModalAddUpdateOptions.process ===
                                "post" && canItPass
                                ? _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "success",
                                        disabled:
                                          _vm.vendorCarModalAddUpdateBtnDisable,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vendorCarAddUpdate()
                                        },
                                      },
                                    },
                                    [_vm._v(" Ekle ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CCard",
            { staticClass: "p-0 m-0 cardShadow" },
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CContainer",
                    { staticClass: "p-0 m-0" },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Durum",
                                  value: _vm.vendorCarModalAddUpdateForm.status,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormStatusOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "status",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Firma",
                                  value:
                                    _vm.vendorCarModalAddUpdateForm.vendorSlug,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormVendorSlugOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "vendorSlug",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: { label: "Araç Kodu" },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .vendorCarCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "vendorCarCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.vendorCarCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: { label: "Araç Markası" },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .vendorCarBrand,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "vendorCarBrand",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.vendorCarBrand",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: { label: "Araç Modeli" },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .vendorCarModel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "vendorCarModel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.vendorCarModel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: {
                                  label: "Günlük KM Limiti",
                                  type: "number",
                                  step: "1",
                                  min: "1",
                                  max: "10000",
                                },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .dailyRangeLimit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "dailyRangeLimit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.dailyRangeLimit",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Yakıt Tipi",
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .vendorCarFuel,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormVendorCarFuelOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "vendorCarFuel",
                                      $event
                                    )
                                  },
                                  change: function ($event) {
                                    return _vm.getCars(
                                      _vm.vendorCarModalAddUpdateForm
                                        .vendorCarFuel,
                                      _vm.vendorCarModalAddUpdateForm
                                        .vendorCarTransmission
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Vites Tipi",
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .vendorCarTransmission,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormVendorCarTransmissionOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "vendorCarTransmission",
                                      $event
                                    )
                                  },
                                  change: function ($event) {
                                    return _vm.getCars(
                                      _vm.vendorCarModalAddUpdateForm
                                        .vendorCarFuel,
                                      _vm.vendorCarModalAddUpdateForm
                                        .vendorCarTransmission
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("label", {}, [_vm._v("Eşlenecek Araç")]),
                              _c("v-select", {
                                attrs: {
                                  reduce: (
                                    vendorCarModalAddUpdateFormCarsOptions
                                  ) =>
                                    vendorCarModalAddUpdateFormCarsOptions.value,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormCarsOptions,
                                  value: _vm.vendorCarModalAddUpdateForm.carId,
                                },
                                model: {
                                  value: _vm.vendorCarModalAddUpdateForm.carId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "carId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.carId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Yakıt Politikası",
                                  value:
                                    _vm.vendorCarModalAddUpdateForm.fuelPolicy,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormFuelPolicyOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "fuelPolicy",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: {
                                  label: "Depozito",
                                  type: "number",
                                  step: "1",
                                  min: "0",
                                },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm.deposit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "deposit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.deposit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Çift Kredi Kartı",
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .doubleCreditCard,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormDoubleCreditCardOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "doubleCreditCard",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: {
                                  label: "Hasar Muafiyeti",
                                  type: "number",
                                  step: "1",
                                  min: "0",
                                },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .damageExemption,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "damageExemption",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.damageExemption",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: {
                                  label: "Sürücü Yaşı (Min)",
                                  type: "number",
                                  step: "1",
                                  min: "18",
                                },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm.driverAge,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "driverAge",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.driverAge",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "4" } },
                            [
                              _c("CInput", {
                                attrs: {
                                  label: "Ehliyet Yaşı (Min)",
                                  type: "number",
                                  step: "1",
                                  min: "1",
                                },
                                model: {
                                  value:
                                    _vm.vendorCarModalAddUpdateForm
                                      .licenseYears,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.vendorCarModalAddUpdateForm,
                                      "licenseYears",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "vendorCarModalAddUpdateForm.licenseYears",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "pairingModal modalNoBorder",
          attrs: {
            title: "Toplu Eşleştirme",
            size: "xl",
            closeOnBackdrop: false,
            show: _vm.pairingModalStatus,
          },
          on: {
            "update:show": function ($event) {
              _vm.pairingModalStatus = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "success",
                        disabled: _vm.vendorCarModalAddUpdateBtnDisable,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.vendorCarsPairing()
                        },
                      },
                    },
                    [_vm._v("Eşleştir ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CCard",
            { staticClass: "p-0 m-0 cardShadow" },
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CContainer",
                    { staticClass: "p-0 m-0" },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "12" } },
                            [
                              _c(
                                "CAlert",
                                { attrs: { show: "", color: "info" } },
                                [
                                  _vm._v(" Seçtiğiniz "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.pairingForm.vendorCars.length
                                      ) + " araç"
                                    ),
                                  ]),
                                  _vm._v(" toplu olarak güncellenecek! "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "2" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Durum",
                                  value: _vm.pairingForm.status,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormStatusOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.pairingForm,
                                      "status",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "6" } },
                            [
                              _c("label", {}, [_vm._v("Eşlenecek Araç")]),
                              _c("v-select", {
                                attrs: {
                                  reduce: (
                                    vendorCarModalAddUpdateFormCarsOptions
                                  ) =>
                                    vendorCarModalAddUpdateFormCarsOptions.value,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormCarsOptions,
                                  value: _vm.pairingForm.carId,
                                },
                                model: {
                                  value: _vm.pairingForm.carId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pairingForm, "carId", $$v)
                                  },
                                  expression: "pairingForm.carId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "placementModal modalNoBorder",
          attrs: {
            title: "Sıra Puanı Güncelleme",
            size: "sm",
            closeOnBackdrop: false,
            show: _vm.placementModalStatus,
          },
          on: {
            "update:show": function ($event) {
              _vm.placementModalStatus = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "success",
                        disabled: _vm.vendorCarModalAddUpdateBtnDisable,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updatePlacementScore()
                        },
                      },
                    },
                    [_vm._v("Güncelle ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CCard",
            { staticClass: "p-0 m-0 cardShadow" },
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CContainer",
                    { staticClass: "p-0 m-0" },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "12" } },
                            [
                              _c(
                                "CAlert",
                                { attrs: { show: "", color: "info" } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.placementForm.vendorCars.length
                                      ) + " araç"
                                    ),
                                  ]),
                                  _vm._v(" puanı güncellenecek! "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "12" } },
                            [
                              _c("CInput", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  label: "Sıra Puanı",
                                  type: "number",
                                  required: "",
                                },
                                model: {
                                  value: _vm.placementForm.placementScore,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.placementForm,
                                      "placementScore",
                                      $$v
                                    )
                                  },
                                  expression: "placementForm.placementScore",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "placementModal modalNoBorder",
          attrs: {
            title: "Toplu Durum Güncelleme",
            size: "sm",
            closeOnBackdrop: false,
            show: _vm.bulkStatusModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.bulkStatusModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "success",
                        disabled: _vm.vendorCarModalAddUpdateBtnDisable,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateBulkStatus()
                        },
                      },
                    },
                    [_vm._v("Güncelle ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CCard",
            { staticClass: "p-0 m-0 cardShadow" },
            [
              _c(
                "CCardBody",
                [
                  _c(
                    "CContainer",
                    { staticClass: "p-0 m-0" },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "12" } },
                            [
                              _c(
                                "CAlert",
                                { attrs: { show: "", color: "info" } },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.placementForm.vendorCars.length
                                      ) + " araç"
                                    ),
                                  ]),
                                  _vm._v(" durumu güncellenecek! "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12", lg: "12" } },
                            [
                              _c("CSelect", {
                                attrs: {
                                  label: "Durum",
                                  value: _vm.bulkStatusForm.status,
                                  options:
                                    _vm.vendorCarModalAddUpdateFormStatusOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.bulkStatusForm,
                                      "status",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }